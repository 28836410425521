<template>
  <div>
    <div class="m-2" style="color: #696F8C; background-color: #fff;">
      <v-form @submit.prevent="updateForm">
        <div class="pt-2">
          <label class="font-weight-bold">Access level name</label>
          <div class="required-text">Required</div>
          <v-text-field
            class="pt-4"
            outlined
            v-model="name"
            label="Name"
            required
          ></v-text-field>
        </div>
        <div class="text-area">
          <label class="font-weight-bold">Description</label>
          <v-textarea
            class="pt-4"
            v-model="description"
            label="Ex. Dealers in everything gadgets"
            outlined
            rows="5"
            clearable
            hint="Please enter your text here"
            required
          >
          </v-textarea>
        </div>
        <div class="submit-buttons">
          <v-btn type="submit" @click.prevent="cancelForm">Cancel</v-btn>
          <v-btn type="submit" class="primary" :disabled="isActive">Next</v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      description: "",
      name: "",
      active: false,
    };
  },
  computed: {
    isActive() {
      return this.name !== "" ? this.active : !this.active
    }
  },

  methods: {
    async cancelForm() {
        this.$router.push("/access-level");
        this.$store.commit("access/SET_FORM_NAME", "");
        this.$store.commit("access/SET_FORM_DESCRIPTION", "");
        this.$store.commit("access/SET_PERMISSIONS", []);
    },
    async updateForm(){
      if(this.$route.path !== "/permissions" && this.name !== ""){
        this.$router.push("/permissions");
        this.$store.commit("access/SET_FORM_NAME", this.name);
        this.$store.commit("access/SET_FORM_DESCRIPTION", this.description);
      }
    }
  },
};
</script>

<style scoped>


.access-name {
  display: flex;
  flex-direction: column;
}

.submit-buttons {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.required-text {
  position: absolute;
  top: 8px;
  right: 8px;
  color: red;
  background: #F9DADA;
  border-radius: 5px;
  font-size: 12px;
}

.text-area {
  position: relative;
}

.v-text-field--outlined >>> fieldset {
  border-color: #E6E8F0;
}
</style>
